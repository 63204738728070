import type { SourceImgConfig } from '~/components/Ysc/YscPicture.vue'

export const useTwicpicsImage = ({ withRefitTransformation = false, withCoverTransformation = false }: { withRefitTransformation?: boolean, withCoverTransformation?: boolean }) => {
  const addTwicpicsTransformations = ({ url, transformations }: { url: URL, transformations: TwicPicsTransformations[] }) => {
    let originParameter = url.searchParams.get('twic') ?? 'v1'
    const joinedTransformations = transformations.map(({ parameter, value }) => `${parameter}=${value}`).join('/')

    if (joinedTransformations.length) {
      originParameter = `${originParameter}/${joinedTransformations}`
    }

    url.searchParams.set('twic', originParameter)
    return url
  }

  const getCoverTransformation = ({ width, height }: SourceImgConfig) => {
    return { parameter: 'cover', value: `${width}x${height}` }
  }

  const getRefitTransformation = ({ width, height, refitParameter }: SourceImgConfig) => {
    if (refitParameter) {
      return { parameter: 'refit', value: `${width}x${height}${refitParameter}` }
    }
    return { parameter: 'refit', value: `${width}x${height}` }
  }

  const getSourceUrl = ({ path, config }: { path: string, config: SourceImgConfig }) => {
    const url = useTwicpicsURL()
    url.pathname = path
    const transformations = []
    if (withRefitTransformation) {
      transformations.push(getRefitTransformation(config))
    }
    if (withCoverTransformation) {
      transformations.push(getCoverTransformation(config))
    }

    if (config.postRefitTransformation) {
      transformations.push(...config.postRefitTransformation)
    }
    return addTwicpicsTransformations({ url, transformations })
  }

  return { addTwicpicsTransformations, getCoverTransformation, getRefitTransformation, getSourceUrl }
}
